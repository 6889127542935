<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="plus" @click="showUpdate('add')" style="margin-right: 10px">添加二维码</a-button>
        </div>
      </div>

      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="is_valid" slot-scope="record">{{record.is_valid == 1 ? '启用' : '禁用'}}</template>
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="showUpdate(record)" >编辑</a-button>
            <a-divider type="vertical" />
            <a-button type="link" @click="deleteFn(record)" >删除</a-button>
            <a-divider type="vertical" />
            <a-divider type="vertical" />
            <a-button v-if="record.is_valid == 0" type="primary" icon="check-circle" @click="onChangeSwitch(record)">启用
            </a-button>
            <a-button v-else icon="close-circle" @click="onChangeSwitch(record)">禁用</a-button>
          </template>
          <template slot="lookPic" slot-scope="record">
            <a-button type="link" icon="picture" @click="showPic(record)">查看二维码</a-button>
          </template>
        </a-table>
      </div>
    </div>

    <a-modal v-model="visible" :title="addOffice ? '添加消费二维码' : '编辑消费二维码'" @ok="handleSure"
      :okText="addOffice ? '保存' : '提交修改'">
      <a-form-model :model="addParam" :rules="rules" ref="addForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 14 }">
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="addParam.title" placeholder="输入标题" />
        </a-form-model-item>
        <a-form-model-item label="金额">
          <a-input v-model="addParam.price" placeholder="输入金额（不填则用户手动输入）" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal width="410px"  v-model="picVisible" :footer="null"  @cancel="closeQrcode">
      <div style="text-align:center;">
        <canvas id="qrcodeCanvas" width="360" height="480"></canvas>
        <!-- <p>鼠标右键点击图片->图片另存为可保存图片到本地</p> -->
        <a-button
            style="margin: 20px;width: 230px;"
            type="primary"
            icon="printer"
            @click="downloadQrcode"
            >保存图片到本地</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  getConsumeQrcode,
  addConsumeQrcode,
  delConsumeQrcode,
  editConsumeQrcode,
  swichtQrcode,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
import qrcodeTools from "@/utils/qrcode";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐管理",
        },
        {
          key: 1,
          title: "消费二维码",
        },
      ],
      inputs: [
        {
          placeholder: "请输入二维码标题",
          model: "title",
          width: 200,
        },
      ],
      //请求参数
      queryParam: {
        page: 1,
        title: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "标题",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "状态",
          key: "is_valid",
          scopedSlots: { customRender: "is_valid" },
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
        {
          title: "查看",
          key: "lookPic",
          scopedSlots: { customRender: "lookPic" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },

      visible: false,
      addOffice: false,
      //更新参数
      addParam: {
        title: "",
        price: "",
      },
      picVisible: false,
      picUrl: "",
      //新增表单校验规则
      rules: {
        title: [
          { required: true, message: "请输入二维码名称", trigger: "blur" },
        ],
        // rank: [{ required: true, message: "请输入排序", trigger: "rank" }],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        title: val.input.title ? val.input.title : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      getConsumeQrcode(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        //空列表判断
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        //列表数据处理
        this.tableData = resultData.data;
        this.loading = false;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //显示添加模态框
    showUpdate (record) {
      if (record == "add") {
        this.addOffice = true;
        this.addParam = {
          title: "",
          price: "",
          is_valid: 1,
        };
      } else {
        this.addOffice = false;
        this.addParam = {
          id: record.id,
          title: record.title,
          price: record.price,
          is_valid: record.is_valid,
        };
      }

      this.visible = true;
    },

    //提交表单
    handleSure () {
      if (this.addOffice) {
        addConsumeQrcode(this.addParam)
          .then((res) => {
            this.$message.success("添加成功");
            this.visible = false;
            this.queryParam.page = 1;
            this.pagination.current = 1;
            this.getTableData();
          })
          .catch((err) => {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          });
      } else {
        editConsumeQrcode(this.addParam.id, this.addParam)
          .then((res) => {
            this.$message.success("修改成功");
            this.visible = false;
            this.getTableData();
          })
          .catch((err) => {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          });
      }
    },

    //删除职务
    deleteFn (record) {
      this.$confirm({
        content: "是否删除该二维码？",
        onOk: () => {
          delConsumeQrcode(record.id)
            .then((res) => {
              this.$message.success("删除成功");
              // this.queryParam.page = 1;
              // this.pagination.current = 1;
              this.getTableData();
            })
            .catch((err) => {
            });
        },
      });
    },

    showPic (record) {
      // this.picUrl = record.qrcode;
      this.picVisible = true;
      let inst_info = JSON.parse(localStorage.getItem("inst"));
      setTimeout(()=>{
        let canvas = document.getElementById('qrcodeCanvas');
        qrcodeTools.generateQrcode(canvas,record.qrcode,inst_info.title,inst_info.id,record.title);
      },500);
      //canvas:Canvas元素； imgUrl:二维码URL； title: 食堂名称; id:食堂ID
    },
    closeQrcode(){
      let canvas = document.getElementById('qrcodeCanvas');
      qrcodeTools.removeQrcode(canvas);
    },
    downloadQrcode(){
        let canvas = document.getElementById('qrcodeCanvas')
        let data = canvas.toDataURL("image/png")
        qrcodeTools.saveCanvasImg(data)
    },
    onChangeSwitch (row) {
      if (row.is_valid == 1) {
        this.$confirm({
          content: "确定禁用该二维码？",
          onOk: () => {
            swichtQrcode(row.id, { is_valid: 0 }).then((res) => {
              this.$message.success("禁用成功");
              // this.queryParam.page = 1;
              // this.pagination.current = 1;
              this.getTableData();
            });
          },
        });
      } else {
        this.$confirm({
          content: "确定启用该二维码？",
          onOk: () => {
            swichtQrcode(row.id, { is_valid: 1 }).then((res) => {
              this.$message.success("开启成功");
              this.queryParam.page = 1;
              this.pagination.current = 1;
              this.getTableData();
            });
          },
        });
      }
    },



  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>