import { getBase64Img } from "@/request/api";
// 接收接口返回的文件流并下载
function generateQrcode (canvas,qrcode,title,id,subtitle) {

  
  // ctx就是我的纸了
  const ctx = canvas.getContext('2d');
  var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  for(var i = 0; i < imageData.data.length; i += 4) {
    // 当该像素是透明的,则设置成白色
    if(imageData.data[i + 3] == 0) {
      imageData.data[i] = 255;
      imageData.data[i + 1] = 255;
      imageData.data[i + 2] = 255;
      imageData.data[i + 3] = 255;
    }
  }
  ctx.putImageData(imageData, 0, 0);
  var bgImg = new Image();
  bgImg.src = require('../assets/qrcode_bg.png')//"/img/qrcode_bg.png";
  //待图片加载完后，将其显示在canvas上
  bgImg.onload = function(){
      ctx.drawImage(this, 0, 0, 360, 480);//this即是imgObj,保持图片的原始大小：470*480
      //ctx.drawImage(this, 0, 0,1024,768);//改变图片的大小到1024*768
      // 设置字体大小
      ctx.font = "14px Microsoft YaHei"
      // 更改字号后，必须重置对齐方式，否则居中麻烦。设置文本的垂直对齐方式
      ctx.textBaseline = 'middle'
      ctx.textAlign = 'left'
      // 文字颜色
      ctx.fillStyle = "#000000"
      // 文字在画布的位置
      ctx.fillText(title+"（"+id+"）", 15, 35)

      //画直线
      ctx.moveTo(15,55);
      ctx.lineTo(105,55);
      ctx.strokeStyle = '#4c4c33'
      ctx.stroke();

      ctx.fillStyle = "#000000"
      ctx.font = "12px Microsoft YaHei"
      ctx.fillText(subtitle, 15, 80)
  }

  getBase64Img({img:qrcode}).then(res=>{
    console.log(res)
    var imgObj = new Image();
    imgObj.src = res.data
    imgObj.onload = function(){
        ctx.drawImage(this, 40, 155, 280, 280);//this即是imgObj,保持图片的原始大小：470*480
        //ctx.drawImage(this, 0, 0,1024,768);//改变图片的大小到1024*768
    }
  });
  
}

function saveCanvasImg(data,filename){
  var link = document.createElement("a");
  link.download = "join.png";
  link.href = data;
  link.click()
  link.remove()
}

function removeQrcode(canvas){
  console.log('销毁canvas');
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
}

export default {
  generateQrcode,
  saveCanvasImg,
  removeQrcode
}